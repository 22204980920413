import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M20.125 7H3.875A1.745 1.745 0 0 0 2.13 8.87l.535 7.725C2.95 20.67 5.05 22.49 7 22.715c3.322.38 6.678.38 10 0 1.95-.225 4.05-2.045 4.335-6.12l.535-7.725A1.746 1.746 0 0 0 20.125 7Zm-.22 9.5c-.145 2.065-1.035 4.565-3.07 4.795a42.68 42.68 0 0 1-9.675 0c-2.03-.23-2.92-2.73-3.065-4.795L3.56 8.775a.3.3 0 0 1 .187-.31.325.325 0 0 1 .128-.025h16.25a.3.3 0 0 1 .23.1.287.287 0 0 1 .085.235l-.535 7.725ZM8.5 5.5H7a5 5 0 0 1 10 0h-1.5a3.5 3.5 0 0 0-7 0Z"
    }, null, -1)
  ])))
}
export default { render: render }