<template>
  <span
    class="relative block"
    data-test-id="cart-icon"
    :class="{
      'animate-jump animate-once': animateOnUpdate && isAmountUpdating,
    }"
  >
    <ClientOnly>
      <template #fallback>
        <CartEmpty class="pictogram" :class="[sizeClass, emptyClass]" />
      </template>

      <CartFilled
        v-if="isFull"
        class="pictogram"
        :class="[sizeClass, pictogramClass]"
      />

      <CartEmpty
        v-else
        class="pictogram"
        :class="[sizeClass, pictogramClass]"
      />

      <span
        v-show="cartAmount"
        class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center text-center font-bold"
        :class="cartAmountClass"
        :style="{ marginTop: '10%' }"
        >{{ cartAmount > 99 ? '99+' : cartAmount }}</span
      >
    </ClientOnly>
  </span>
</template>

<script setup lang="ts">
import CartEmpty from '~/assets/pictograms/dynamic/cart.svg?component';
import CartFilled from '@brand/assets/pictograms/dynamic/cart-filled.svg?component';

interface Props {
  cartAmount?: number;
  sizeClass?: string;
  emptyClass?: string;
  filledClass?: string;
  cartAmountClass?: string;
  animateOnUpdate?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  cartAmount: 0,
  sizeClass: 'w-6 h-6',
  emptyClass: 'text-black/54',
  filledClass: 'cart-filled-color-class',
  cartAmountClass: 'text-white text-xs',
});

const isFull = computed(() => props.cartAmount);

const isAmountUpdating = ref(false);
if (props.animateOnUpdate) {
  watch(
    () => props.cartAmount,
    () => {
      if (!props.animateOnUpdate) return;
      isAmountUpdating.value = true;
      setTimeout(() => {
        isAmountUpdating.value = false;
      }, 1000);
    }
  );
}

const pictogramClass = computed(() =>
  props.cartAmount ? props.filledClass : props.emptyClass
);
</script>

<style lang="postcss">
.pictogram svg {
  @apply h-full w-full;
}
</style>
